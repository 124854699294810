<template>
  <div class="blank_asset5">
    <div class="head_tbl">
      <div class="pos_left">
        <strong class="tit_text">정산 정보</strong>
      </div>
      <div class="pos_right row_pack">
        <button type="button" class="btn_medium btn_secondary" @click="addCertificateInfo">
          정산정보 추가
        </button>
        <button type="button" class="btn_medium btn_secondary" @click="removeCertificateInfo">
          <span class="ico_account ico_delete_w" />선택 삭제
        </button>
        <button type="button" class="btn_medium btn_secondary" @click="checkInfoToCopy">
          <span class="ico_account ico_copy_w" />선택 복사
        </button>
      </div>
    </div>
    <ul class="list_fold">
      <StatementInfoLine
        v-for="(item, index) in certificateList"
        :key="index"
        :certificateItemData="item"
        :index="index"
        :checkedNames.sync="checkedNames"
        :inspectHistory="inspectHistory"
        :isInfraStatement="isInfraStatement"
        :model="model"
        :purchaseContract.sync="purchaseContractSync"
        :docflag="docflag"
        :apprType="apprType"
        :deptInfo.sync="deptInfo"
        :isAddApprover="isAddApprover"
        @removeStatementLine="removeStatementLine"
        @copyStatementLine="copyStatementLine"
      />
    </ul>
  </div>
</template>

<script>
import StatementInfoLine from "./StatementInfoLine";
import CertificateInfoModel from "@/_approval/components/statement/write/CertificateInfoModel";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import VoucherItemsModel from "@/_approval/components/statement/write/VoucherItemsModel";
import SettlementDataModel from "@/_approval/components/statement/write/SettlementDataModel";

export default {
  name: "StatementInfo",
  components: {
    StatementInfoLine,
  },
  mixins: [CommLayerMixin],
  props: {
    certificateList: Array,
    inspectHistory: Array,
    isInfraStatement: Boolean,
    purchaseContract: Object,
    model: Object,
    docflag: String,
    apprType: String,
    deptInfo: Object,
    isAddApprover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataList: [],
      checkedNames: [],
      settlementDataModel: new SettlementDataModel(),
    };
  },
  computed: {
    purchaseContractSync: {
      get() {
        return this.purchaseContract;
      },
      set(value) {
        this.$emit("update:purchaseContract", value);
      },
    },
  },
  methods: {
    addCertificateInfo() {
      const model = new CertificateInfoModel();
      const idx = this.certificateList.length;

      model.orderNum = idx;

      this.certificateList.push(model);
      this.$emit("setVoucherItems", true, idx);

      if (this.isInfraStatement) {
      } else {
      }

      this.$emit("setProjectCode");
    },
    removeStatementLine(idx) {
      if (idx !== null) {
        this.certificateList.splice(idx, 1);

        return;
      }
    },
    removeCertificateInfo() {
      this.checkedNames.forEach((item) => {
        this.certificateList.splice(this.certificateList.indexOf(item), 1);
      });

      this.checkedNames = [];
    },
    copyStatementLine(idx, data) {
      if (idx !== null) {
        this.checkedNames.push(idx);

        this.copyCertificateInfo(data);
      }
    },
    checkInfoToCopy() {
      if (this.checkedNames.length == 0) {
        this._showLayer({
          type: "alert",
          content: "정산정보를 선택해주세요",
        });

        return;
      }
      //this.copyCertificateInfo(this.checkedNames[0]);
      this.copyCertificateInfo();
    },
    copyCertificateInfo(data) {
      if (data) {
        const copiedObj = JSON.parse(JSON.stringify(data));

        const obj = this.settlementDataModel.setCertificateList(copiedObj);
        obj.orderNum = this.certificateList.length;
        obj.voucher.voucherItems.forEach((voucherItem, index) => {
          voucherItem.origin = data.voucher.voucherItems[index].origin;
        });
        if (!data.order.orderCid) {
          obj.order = {};
        }

        this.certificateList.push(obj);
        return;
      }

      this.checkedNames.forEach((idx) => {
        const item = this.certificateList[idx];
        const parsedItem = JSON.parse(JSON.stringify(item));
        const obj = this.settlementDataModel.setCertificateList(parsedItem);

        obj.orderNum = this.certificateList.length;
        obj.voucher.voucherItems.forEach((voucherItem, index) => {
          voucherItem.origin = item.voucher.voucherItems[index].origin;
        });
        if (!item.order.orderCid) {
          obj.order = {};
        }
        this.certificateList.push(obj);
      });
    },
  },
};
</script>
<style scoped>
.list_fold {
  border-top: 1px solid #222;
}
</style>
