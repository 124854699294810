import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";

import BilFilesInfoModel from "./BilFilesInfoModel";
import EtcFilesInfoModel from "./EtcFilesInfoModel";
import ContractInfoModel from "./ContractInfoModel";
import ContractInspect from "./ContractInspectModel";
import OrderInfoModel from "./OrderInfoModel";
import VoucherInfoModel from "./VoucherInfoModel";
// import PartnerInfoModel from './PartnerInfoModel'
import PaymentInfoModel from "./PaymentInfoModel";

class CertificateInfoModel {
  constructor(obj) {
    this.assetAcquisitionDate = ""; // 취득일자

    this.certificateDesc = ""; // 정산 내용
    this.certificateId = null; // 정산 번호

    // 계약정보 배열에서 객체로 수정 ( 한개의 계약만 가능)
    //this.contract = [];
    this.contract = {};

    // 검수
    this.contractInspect = [];

    // 사업자 등록증 사본
    this.businessRegFile = {};
    // 통장사본
    this.bankBookFile = {};
    // 세금계산서/계산서 자료
    this.billFiles = [];
    // 기타 증빙자료
    this.etcFiles = [];

    // 발주 배열에서 객체로 수정 ( 한개의 발주만 가능)
    // this.order = [];
    this.order = new OrderInfoModel();

    (this.orderNum = 0), // 순서
      // 지급처
      // this.partner = new PartnerInfoModel();
      (this.payment = new PaymentInfoModel());
    // this.proofDate = null, // 증빙일자
    this.proofDate = ""; // 증빙일자
    this.proofName = ""; // 증빙명
    this.proofNum = ""; // 증빙번호(국세청 증빙번호)
    this.proofType = ""; // 증빙유형 // -> 세금계산서
    this.settlementCid = null; // 정산서 번호
    // 전표
    this.voucher = new VoucherInfoModel();
    // 사업자등록번호 (필수)
    this.corporateNum = "";
    // 지급처 (필수)
    this.partnerName = "";
    this.userNum = 0; // 사용자 번호
  }
  setData(obj) {
    const {
      certificateDesc,
      certificateId,
      contract,
      contractInspect,
      bankBookFile,
      businessRegFile,
      billFiles,
      etcFiles,
      order,
      orderNum,
      payment,
      proofDate,
      proofName,
      proofNum,
      proofType,
      settlementCid,
      voucher,
      assetAcquisitionDate,
      corporateNum,
      partnerName,
      userNum,
    } = obj;
    this.assetAcquisitionDate = assetAcquisitionDate;

    this.certificateDesc = certificateDesc || "";
    this.certificateId = certificateId || null;
    this.contract = contract || {};
    // this.contract = contract.map(item => {
    //     const itemModel = new ContractInfoModel();
    //     itemModel.setData(item);
    //     return itemModel;
    // }) || {};
    // 검수
    if (!contractInspect) {
      this.contractInspect = [];
    } else {
      this.contractInspect =
        contractInspect.map((item) => {
          const itemModel = new ContractInspect();
          itemModel.setData(item);
          return itemModel;
        }) || [];
    }

    // 사업자/주민등록등본 사본
    this.businessRegFile = businessRegFile || {};

    // 통장사본
    this.bankBookFile = bankBookFile || {};

    // 세금계산서/계산서
    if (!billFiles) {
      this.billFiles = [];
    } else {
      this.billFiles = billFiles.map((item) => {
        const itemModel = new BilFilesInfoModel();
        itemModel.setData(item);
        return itemModel;
      });
    }

    // 기타 증빙자료
    this.etcFiles = etcFiles || [];
    // 발주
    if (order) {
      const itemModel = new OrderInfoModel();
      itemModel.setData(order);
      this.order = itemModel;
    } else {
      this.order = {};
    }
    this.orderNum = orderNum || 0; // 순서
    // 지급처
    //  this.partner = partner || {};
    //  this.partner = partner.map(item => {
    //     const itemModel = new PartnerInfoModel();
    //     itemModel.setData(item);
    //     return itemModel;
    // }) || {};
    // if(partner) {
    //     // const itemModel = new PartnerInfoModel();
    //     // itemModel.setData(partner);
    //     // this.partner = itemModel;
    //     this.partner.setData(partner);
    // } else {
    //     this.partner = {};
    // }
    if (payment) {
      this.payment.setData(payment);
    } else {
      this.payment = {};
    }
    this.proofDate = proofDate; //stringToDateWithTime(proofDate) || null, // 증빙일자
    this.proofName = proofName || ""; // 증빙명
    this.proofNum = proofNum || ""; // 증빙번호(국세청 증빙번호)
    this.proofType = proofType || ""; // 증빙유형
    this.settlementCid = settlementCid || null; // 정산서 번호
    // 전표
    if (voucher) {
      const itemModel = new VoucherInfoModel();
      itemModel.setData(voucher);
      this.voucher = itemModel;
    } else {
      this.voucher = {};
    }

    //this.voucher = voucher || {};
    //  this.voucher = voucher.map(item => {
    //     const itemModel = new VoucherInfoModel();
    //     itemModel.setData(item);
    //     return itemModel;
    // }) || {};

    if (corporateNum) this.corporateNum = corporateNum;
    if (partnerName) this.partnerName = partnerName;
    if (userNum) this.userNum = userNum;
  }

  getData() {
    let obj = {
      assetAcquisitionDate: this.assetAcquisitionDate,
      //bilFiles: this.BilFilesInfoModel.getData(),
      //bilFiles: this.billFiles.map(item => item.getData()),
      // bilFiles: bilArray,
      certificateDesc: this.certificateDesc,
      certificateId: this.certificateId,
      contract: this.contract,
      // contractInspect : this.contractInspect.map(item => item.getData()),
      contractInspect: this.contractInspect,
      bankBookFile: this.bankBookFile,
      businessRegFile: this.businessRegFile,
      billFiles: this.billFiles,
      etcFiles: this.etcFiles,
      //etcFiles: this.EtcFilesInfoModel.getData(),
      // etcFiles: etcArray,
      order: !this.order.orderCid ? {} : this.order.getData(),
      orderNum: this.orderNum,
      //partner: this.partner,
      // payment: this.payment,
      // partner: this.partner.getData(),
      payment: this.payment.getData(),
      proofDate: this.proofDate,
      proofName: this.proofName,
      proofNum: this.proofNum,
      proofType: this.proofType,
      settlementCid: this.settlementCid,
      corporateNum: this.corporateNum,
      partnerName: this.partnerName,
      userNum: this.userNum,
      // voucher: this.voucher.map(item => item.getData())
      voucher: this.voucher.getData(),
      // voucher: this.voucher,
      //voucher: this.VoucherInfoModel.getData()
    };

    // if(this.userNum) {
    //     obj.partner = this.partner.getData();
    // } else {
    //     obj.partner = {};
    // }

    // if(this.contract.length > 0) {
    //     obj.contract = this.ContractInfoModel.getData();
    // }

    // if(this.order.length > 0) {
    //     obj.order = this.OrderInfoModel.getData();
    // }

    // if(this.proofDate) {
    //     obj.proofDate = dateToStringWithTime(this.proofDate);
    // } else {
    //     obj.proofDate = null;
    // }
    return obj;
  }
}

export default CertificateInfoModel;
