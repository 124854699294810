import { stringToDateWithTime, dateToStringWithTime } from "@/utils/dateUtils";
import VoucherItemsModel from "./VoucherItemsModel";
import EmailModel from "@/_approval/models/EmailModel";
import WithHoldingTaxModel from "./WithHoldingTaxModel";
//import SurtaxInfoModel from './SurtaxInfoModel'

class VoucherInfoModel {
  constructor() {
    this.objRaw = null;

    this.exchangeRateDate = null; // 환율기준일
    this.expenditureDate = ""; // 지급예정일
    this.regDate = null; // 등록일자
    this.voucherDate = null; // 전표일자
    // this.voucherProofDate = null; // 증빙일자
    this.proofDate = ""; // 증빙일자
    this.voucherSaveModeYn = false; // 전표 생성 여부

    this.erpPartnerName = ""; // 거래처 이름
    (this.erpVoucherNo = ""), (this.budgetCode = "");
    this.currency = "KRW";
    this.erpPartnerCode = "";
    this.exchangeRate = null;

    // 기간인식 인 경우. => voucher 안에 보내주는것인지.
    this.incomeLossAccountCode = null;
    this.incomeLossAccountName = "";
    this.incomeLossDeptCode = "";
    this.incomeLossDeptName = "";
    this.incomeLossServiceCode = "";
    this.incomeLossServiceName = "";
    this.incomeLossTaxItem = "";
    this.incomeLossTaxName = "";
    this.periodEndDate = null;
    this.periodStartDate = null;
    //this.projectCode =  '';
    // this.proofDate =  null;
    this.prospectiveRetroactive = "F";
    this.regDate = null;
    this.status = "";
    this.orderNum = 1;

    // this.goodsName = ''; // 자산명
    // this.amount = ''; // 수량
    // this.assetMainClassification = ''; // 자산분류코드
    // this.assetMainClassificationName = ''; // 인프라 자산분류(서버, 기계장치, 네트워크)
    // this.newUsed = ''; //자산구분(신규/중고) 코드

    //this.surtax = null; // 부가세
    this.surtax = {
      electroWrite: "ELECT", // 전자 E: ELECT , 수기 W: WRITE
      exclusionOfDeductionReason: "", // 불공제사유
      receiptBill: "R", // 영수:R/청구:D
      surtaxCode: "", // 세금유형
      surtaxName: "", //세금유형 이름
      vatAssetType: "", // 자산구분
      voucherCid: "", // 전표 번호
      surtaxRate: "", // 부가세율
      exclusionOfDeductionReasonCode: "", // 불공제사유코드
      taxType: "",
      taxNm: "",
      numberOfVat: null,
    };
    // 원천세
    this.withholdingTax = new WithHoldingTaxModel();

    this.voucherItems = [];
    this.voucherItems.push(new VoucherItemsModel());

    // // 차변 - 대변 고정값
    // const credit = new VoucherItemsModel();
    // credit.serviceCode = 'S999999';
    // credit.accountCode = '1113000000';
    // credit.surtaxYn = true;
    // credit.orderNum = 2;
    // credit.crdr = 'CR';

    // const debit = new VoucherItemsModel();
    // debit.serviceCode = 'S999999';
    // debit.accountCode = '2101020000';
    // debit.orderNum = 3;
    // debit.crdr = 'DR';

    // this.voucherItems.push(credit);
    // this.voucherItems.push(debit);
  }

  setData(obj) {
    this.objRaw = obj;

    const {
      exchangeRateDate,
      expenditureDate,
      regDate,
      surtax,
      withholdingTax,
      budgetCode,
      currency,
      erpPartnerCode,
      erpPartnerName,
      erpVoucherNo,
      exchangeRate,
      incomeLossAccountCode,
      incomeLossAccountName,
      incomeLossDeptCode,
      incomeLossDeptName,
      incomeLossServiceCode,
      incomeLossServiceName,
      incomeLossTaxItem,
      incomeLossTaxName,
      periodEndDate,
      periodStartDate,
      proofDate,
      prospectiveRetroactive,
      status,
      voucherCid,
      voucherDate,
      voucherId,
      voucherSaveModeYn,
    } = obj;

    // const surtaxModel = new SurtaxInfoModel();
    // surtaxModel.setData(surtax);
    // this.surtax = surtaxModel;
    // this.surtax = surtax;
    // this.withholdingTax = withholdingTax;
    if (surtax) {
      this.surtax = surtax;
    } else {
      this.surtax = {
        electroWrite: "ELECT", // 전자 E: ELECT , 수기 W: WRITE
        exclusionOfDeductionReason: "", // 불공제사유
        receiptBill: "R", // 영수:R/청구:D
        vatAssetType: "", // 자산구분
        voucherCid: "", // 전표 번호
        surtaxRate: "",
        exclusionOfDeductionReasonCode: "",
        taxType: "",
        taxNm: "",
        numberOfVat: null,
        surtaxCode: "", // 세금유형
        surtaxName: "", //세금유형 이름
      };
    }
    if (withholdingTax) {
      this.withholdingTax.setData(withholdingTax);
    }

    this.exchangeRateDate = exchangeRateDate; // 환율기준일
    this.expenditureDate = expenditureDate; // 지급예정일
    this.regDate = regDate; // 등록일자

    this.budgetCode = budgetCode;
    this.currency = currency;
    this.erpPartnerCode = erpPartnerCode;
    this.erpPartnerName = erpPartnerName;
    this.erpVoucherNo = erpVoucherNo;
    this.exchangeRate = exchangeRate;
    this.incomeLossAccountCode = incomeLossAccountCode;
    this.incomeLossAccountName = incomeLossAccountName;
    this.incomeLossDeptCode = incomeLossDeptCode;
    this.incomeLossDeptName = incomeLossDeptName;

    this.incomeLossServiceCode = incomeLossServiceCode;
    this.incomeLossServiceName = incomeLossServiceName;
    this.incomeLossTaxItem = incomeLossTaxItem;
    this.incomeLossTaxName = incomeLossTaxName;
    this.periodEndDate = periodEndDate;
    this.periodStartDate = periodStartDate;
    this.proofDate = proofDate;
    this.prospectiveRetroactive = prospectiveRetroactive;
    this.status = status;
    this.voucherCid = voucherCid;

    this.voucherDate = voucherDate;
    this.voucherId = voucherId;
    this.voucherSaveModeYn = voucherSaveModeYn;

    if (this.objRaw.voucherItems) {
      this.voucherItems = this.objRaw.voucherItems.map((item) => {
        const itemModel = new VoucherItemsModel();
        itemModel.setData(item);
        return itemModel;
      });
    }
  }

  getData() {
    //  const surtaxInfo = this.SurtaxInfoModel.getData();
    const obj = Object.assign({}, this.objRaw);

    if (this.surtax && this.surtax.taxCode) obj.surtax = this.surtax;
    if (this.withholdingTax.withholdingTaxCode) {
      obj.withholdingTax = this.withholdingTax.getData();
    } else {
      obj.withholdingTax = {};
    }
    obj.voucherDate = this.voucherDate;
    if (this.exchangeRateDate) obj.exchangeRateDate = this.exchangeRateDate;
    //   if(this.expenditureDate) obj.expenditureDate = dateToStringWithTime(this.expenditureDate);
    if (this.expenditureDate) obj.expenditureDate = this.expenditureDate;

    if (this.voucherCid) obj.voucherCid = this.voucherCid;
    if (this.voucherid) obj.voucherid = this.voucherid;
    obj.voucherSaveModeYn = Boolean(this.voucherSaveModeYn);
    if (this.voucherItems) {
      obj.voucherItems = this.voucherItems.map((item) => item.getData());
      //obj.voucherItems = this.voucherItems;
    }
    obj.erpPartnerName = this.erpPartnerName;
    obj.erpPartnerCode = this.erpPartnerCode;
    obj.erpVoucherNo = this.erpVoucherNo;
    //obj.projectCode = this.projectCode;

    // 기간인식
    if (this.incomeLossAccountCode) obj.incomeLossAccountCode = this.incomeLossAccountCode;
    if (this.incomeLossAccountName) obj.incomeLossAccountName = this.incomeLossAccountName;
    if (this.incomeLossDeptCode) obj.incomeLossDeptCode = this.incomeLossDeptCode;
    if (this.incomeLossDeptName) obj.incomeLossDeptName = this.incomeLossDeptName;
    if (this.incomeLossServiceCode) obj.incomeLossServiceCode = this.incomeLossServiceCode;
    if (this.incomeLossServiceName) obj.incomeLossServiceName = this.incomeLossServiceName;
    if (this.incomeLossTaxItem) obj.incomeLossTaxItem = this.incomeLossTaxItem;
    if (this.incomeLossTaxName) obj.incomeLossTaxName = this.incomeLossTaxName;
    if (this.periodEndDate) obj.periodEndDate = this.periodEndDate;
    if (this.periodStartDate) obj.periodStartDate = this.periodStartDate;
    if (this.proofDate) obj.proofDate = this.proofDate;
    if (this.prospectiveRetroactive) obj.prospectiveRetroactive = this.prospectiveRetroactive;

    return obj;
  }
}

export default VoucherInfoModel;
